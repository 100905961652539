import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { RoutingPipe } from '@dougs/core/routing';
import { DividerComponent, TagComponent } from '@dougs/ds';
import { NavElement } from '../../dto';
import { NavigationItemComponentService } from '../../services/navigation/navigation-item.component.service';

const ANIMATION = '0.3s ease-in-out';

@Component({
  selector: 'dougs-navigation-item',
  standalone: true,
  imports: [CommonModule, RouterLink, RoutingPipe, RouterLinkActive, DividerComponent, TagComponent],
  providers: [NavigationItemComponentService],
  templateUrl: './navigation-item.component.html',
  styleUrl: './navigation-item.component.scss',
  changeDetection: ChangeDetectionStrategy.Default,
  animations: [
    trigger('slideUpDown', [
      state('up', style({ height: 0, overflow: 'hidden' })),
      state('down', style({ height: '*', overflow: 'hidden' })),
      transition('up => down', [
        style({ height: 0, overflow: 'hidden' }),
        animate(ANIMATION, style({ height: '*', marginTop: '*', opacity: 1 })),
      ]),
      transition('down => up', [
        style({ overflow: 'hidden' }),
        animate(ANIMATION, style({ height: 0, marginTop: 0, opacity: 0 })),
      ]),
    ]),
  ],
})
export class NavElementComponent {
  @Input({ required: true })
  set navElement(navElement: NavElement) {
    this.navigationItemComponentService.setNavElement(navElement);
  }

  @Output() hideMenu: EventEmitter<void> = this.navigationItemComponentService.hideMenuEmitter;

  constructor(public readonly navigationItemComponentService: NavigationItemComponentService) {}
}
