@if (onToggleSidebarMenu$ | async) {}
@if (sidebarCompactComponentService.disableCompactUnfoldedOnNavigation$ | async) {}
@if (!(sidebarModuleAccessComponentService.hideOpenMenuIcon$ | async)) {
  <i (click)="openMenu($event)" class="fas fa-bars trigger-menu"></i>
}
<dougs-sidebar-menu
  (clickOutside)="onClickOutsideMenu()"
  [isCompact]="isCompact && !sidebarCompactComponentService.isCompactUnfolded$()"
  [ngClass]="{ 'is-open': isMenuOpened, 'compact-unfolded': sidebarCompactComponentService.isCompactUnfolded$() }"
  class="sidebar-menu"
  dougsClickOutside
  [logoName]="sidebarComponentService.logo$ | async"
  (mouseenter)="sidebarCompactComponentService.onMouseEnter()"
  (mouseleave)="sidebarCompactComponentService.onMouseLeave()"
>
  <dougs-sidebar-menu-item
    *ngIf="
      (sidebarModuleAccessComponentService.shouldShowPerformanceLink$ | async) ||
      (sidebarModuleAccessComponentService.wouldSeeBlankSlate$ | async)
    "
    [isActive]="isRouterActiveAndNotificationClosed(rlaPerformance)"
    [isCompact]="isCompact"
    (click)="sidebarComponentService.sendClickMenuEvent('Performance')"
  >
    <a #rlaPerformance="routerLinkActive" [routerLink]="URL.PERFORMANCE | createUrl | async" routerLinkActive>
      <i class="sidebar-icon fal fa-chart-mixed"></i>
      <span class="menu-title">Performance</span>
    </a>
  </dougs-sidebar-menu-item>
  <dougs-sidebar-menu-item
    *ngIf="sidebarModuleAccessComponentService.shouldShowOnboardingCreationLink$ | async"
    [isActive]="isRouterActiveAndNotificationClosed(rlaEnterprise)"
    [isCompact]="isCompact"
    (click)="sidebarComponentService.sendClickMenuEvent('Creation')"
  >
    <a #rlaEnterprise="routerLinkActive" [routerLink]="URL.ONBOARDING_CREATION | createUrl | async" routerLinkActive>
      <i class="sidebar-icon fal fa-building"></i>
      <span class="menu-title">Création d'entreprise</span>
    </a>
  </dougs-sidebar-menu-item>
  <dougs-sidebar-menu-item
    *ngIf="sidebarModuleAccessComponentService.shouldShowAccountingLink$ | async"
    [isActive]="isRouterActiveAndNotificationClosed(rlaAccounting)"
    [isCompact]="isCompact"
    (click)="sidebarComponentService.sendClickMenuEvent('Accounting')"
  >
    <a #rlaAccounting="routerLinkActive" [routerLink]="URL.ACCOUNTING | createUrl | async" routerLinkActive>
      <i class="sidebar-icon fal fa-money-bills-simple"></i>
      <span class="menu-title">Comptabilité</span>
      <ng-container
        *ngIf="{
          operationsCount: notValidatedOperationsStateService.operationsCount$ | async,
          hasSynchronizingAccount: (synchronizedAccountStateService.synchronizingAccounts$ | async)?.length,
        } as accountingInfo"
      >
        <span *ngIf="accountingInfo.operationsCount && !accountingInfo.hasSynchronizingAccount" class="pill">
          {{ accountingInfo.operationsCount > 99 ? '99+' : accountingInfo.operationsCount }}
        </span>
        <div *ngIf="accountingInfo.hasSynchronizingAccount" class="spinner">
          <i class="fad fa-spinner-third fa-spin fa-lg"></i>
        </div>
      </ng-container>
    </a>
  </dougs-sidebar-menu-item>
  <dougs-sidebar-menu-item
    *ngIf="
      (sidebarModuleAccessComponentService.shouldShowInvoicingLink$ | async) &&
      (sidebarModuleAccessComponentService.shouldShowNewInvoiceLink$ | async)
    "
    [isActive]="isRouterActiveAndNotificationClosed(rlaInvoices)"
    [isCompact]="isCompact"
    (click)="sidebarComponentService.sendClickMenuEvent('Invoices')"
  >
    <a #rlaInvoices="routerLinkActive" [routerLink]="URL.INVOICES | createUrl | async" routerLinkActive>
      <i class="sidebar-icon fal fa-file-invoice"></i>
      <span class="menu-title">Facturation</span>
    </a>
  </dougs-sidebar-menu-item>
  <dougs-sidebar-menu-item
    *ngIf="
      (sidebarModuleAccessComponentService.shouldShowInvoicingLink$ | async) &&
      !(sidebarModuleAccessComponentService.shouldShowNewInvoiceLink$ | async)
    "
    [isActive]="isRouterActiveAndNotificationClosed(rlaInvoicing)"
    [isCompact]="isCompact"
    (click)="sidebarComponentService.sendClickMenuEvent('Invoicing')"
  >
    <a #rlaInvoicing="routerLinkActive" [routerLink]="URL.INVOICING | createUrl | async" routerLinkActive>
      <i class="sidebar-icon fal fa-file-invoice"></i>
      <span class="menu-title">Factures de vente</span>
    </a>
  </dougs-sidebar-menu-item>
  <dougs-sidebar-menu-item
    *ngIf="
      (sidebarModuleAccessComponentService.shouldShowInvoicingLink$ | async) &&
      !(sidebarModuleAccessComponentService.shouldShowNewInvoiceLink$ | async)
    "
    [isActive]="isRouterActiveAndNotificationClosed(rlaServices)"
    [isCompact]="isCompact"
    (click)="sidebarComponentService.sendClickMenuEvent('Supplier Invoicing')"
  >
    <a #rlaServices="routerLinkActive" [routerLink]="URL.VENDOR_INVOICE | createUrl | async" routerLinkActive>
      <i class="sidebar-icon fal fa-receipt"></i>
      <span class="menu-title">Factures d'achat</span>
    </a>
  </dougs-sidebar-menu-item>
  <dougs-sidebar-menu-item
    *ngIf="sidebarModuleAccessComponentService.shouldShowAccountingLinkForBlankSlate$ | async"
    [isActive]="isRouterActiveAndNotificationClosed(rlaAccounting)"
    [isCompact]="isCompact"
    (click)="sidebarComponentService.sendClickMenuEvent('Accounting')"
  >
    <a #rlaAccounting="routerLinkActive" [routerLink]="URL.ACCOUNTING | createUrl | async" routerLinkActive>
      <i class="sidebar-icon fal fa-money-bills-simple"></i>
      <span class="menu-title">Comptabilité</span>
      <span class="pill new"> NEW </span>
    </a>
  </dougs-sidebar-menu-item>
  <dougs-sidebar-menu-item
    *ngIf="sidebarModuleAccessComponentService.shouldShowDeclarationLink$ | async"
    [isActive]="isRouterActiveAndNotificationClosed(rlaDeclaration)"
    [isCompact]="isCompact"
    (click)="sidebarComponentService.sendClickMenuEvent('Declarations')"
  >
    <a #rlaDeclaration="routerLinkActive" [routerLink]="URL.DECLARATION | createUrl | async" routerLinkActive>
      <i class="sidebar-icon fal fa-building-columns"></i>
      <span class="menu-title">Déclarations</span>
    </a>
  </dougs-sidebar-menu-item>
  <dougs-sidebar-menu-item
    *ngIf="sidebarModuleAccessComponentService.shouldShowInvestmentLink$ | async"
    [isActive]="isRouterActiveAndNotificationClosed(rlaInvestment)"
    [isCompact]="isCompact"
    (click)="sidebarComponentService.sendClickMenuEvent('Investments')"
  >
    <a #rlaInvestment="routerLinkActive" [routerLink]="URL.INVESTMENT | createUrl | async" routerLinkActive>
      <i class="sidebar-icon fal fa-house-circle-check"></i>
      <span class="menu-title">Immobilisations</span>
    </a>
  </dougs-sidebar-menu-item>
  <dougs-sidebar-menu-item
    *ngIf="sidebarModuleAccessComponentService.shouldShowAccountingSurveyLink$ | async"
    [isCompact]="isCompact"
    [isActive]="isRouterActiveAndNotificationClosed(rlaAccountingSurvey)"
  >
    <a
      #rlaAccountingSurvey="routerLinkActive"
      [routerLink]="URL.ACCOUNTING_SURVEY | createUrl | async"
      routerLinkActive
    >
      <i class="sidebar-icon fal fa-ballot-check"></i>
      <span class="menu-title">Questions Bilan</span>
    </a>
    <span *ngIf="sidebarComponentService.accountingSurveyCompletion$ | async as percent" class="pill">
      {{ percent }}%
    </span>
  </dougs-sidebar-menu-item>
  <dougs-sidebar-menu-item
    *ngIf="servicesAccessService.canAccessServices$ | async"
    [isActive]="isRouterActiveAndNotificationClosed(rlaServices)"
    [isCompact]="isCompact"
    (click)="sidebarComponentService.sendClickMenuEvent('Prestations')"
  >
    <a #rlaServices="routerLinkActive" [routerLink]="URL.SERVICE | createUrl | async" routerLinkActive>
      <i class="sidebar-icon fal fa-briefcase"></i>
      <span class="menu-title">Prestations</span>
      <ng-container *ngIf="sidebarComponentService.countServicesUncompleted$ | async as countServicesUncompleted">
        <span *ngIf="countServicesUncompleted > 0" class="pill">{{ countServicesUncompleted }}</span>
      </ng-container>
      <ng-container *ngIf="sidebarComponentService.shouldShowNewBadgeOnServices$ | async">
        <span class="pill yellow">NEW</span>
      </ng-container>
    </a>
  </dougs-sidebar-menu-item>
  <dougs-sidebar-menu-item
    *ngIf="sidebarModuleAccessComponentService.shouldShowSeparator$ | async"
    [isCompact]="isCompact"
    dougsSidebarMenuSeparator
  />
  <dougs-sidebar-menu-item
    *ngIf="sidebarModuleAccessComponentService.shouldShowNewSettingsLink$ | async"
    [isActive]="isRouterActiveAndNotificationClosed(rlaSettings)"
    [isCompact]="isCompact"
    (click)="sidebarComponentService.sendClickMenuEvent('Parameters')"
  >
    <a #rlaSettings="routerLinkActive" [routerLink]="URL.SETTINGS_NEW | createUrl | async" routerLinkActive>
      <i class="sidebar-icon fal fa-gear"></i>
      <span class="menu-title">Paramètres</span>
      <span
        *ngIf="
          (companyStateService.activeCompany$ | async)?.completion?.percent &&
          (companyStateService.activeCompany$ | async)?.completion?.percent < 100
        "
        class="pill"
      >
        {{ (companyStateService.activeCompany$ | async)?.completion?.percent }}%
      </span>
    </a>
  </dougs-sidebar-menu-item>
  <dougs-sidebar-menu-item
    *ngIf="sidebarModuleAccessComponentService.shouldShowOldSettingsLink$ | async"
    [isActive]="isRouterActiveAndNotificationClosed(rlaSettings)"
    [isCompact]="isCompact"
    [isAdmin]="!(companyStateService.activeCompany$ | async)?.isCreated"
    (click)="sidebarComponentService.sendClickMenuEvent('Parameters')"
  >
    <a #rlaSettings="routerLinkActive" [routerLink]="URL.SETTINGS | createUrl | async" routerLinkActive>
      <i class="sidebar-icon fal fa-gear"></i>
      <span class="menu-title">Paramètres</span>
      <span
        *ngIf="
          (companyStateService.activeCompany$ | async)?.completion?.percent &&
          (companyStateService.activeCompany$ | async)?.completion?.percent < 100
        "
        class="pill"
      >
        {{ (companyStateService.activeCompany$ | async)?.completion?.percent }}%
      </span>
    </a>
  </dougs-sidebar-menu-item>
  <dougs-sidebar-menu-item
    *ngIf="sidebarModuleAccessComponentService.shouldShowNotificationLink$ | async"
    [isCompact]="isCompact"
    [isActive]="rlaNotifications.isActive"
    (click)="sidebarComponentService.sendClickMenuEvent('Customer Todo')"
  >
    <a
      (click)="$event.preventDefault()"
      #rlaNotifications="routerLinkActive"
      [routerLink]="URL.TODO | createUrl | async"
      routerLinkActive
      data-cy="sidebar-menu-item-todo"
    >
      <i class="sidebar-icon fal fa-check-circle"></i>
      <span class="menu-title">À faire</span>
      <dougs-badge
        *ngIf="(notificationStateService.notifications$ | async)?.length > 0"
        [ngClass]="{ 'ml-8': !isCompact }"
      >
        {{ (notificationStateService.notifications$ | async)?.length }}
      </dougs-badge>
    </a>
  </dougs-sidebar-menu-item>
  <dougs-sidebar-menu-item
    *ngIf="sidebarModuleAccessComponentService.shouldShowWebinars$ | async"
    [isCompact]="isCompact"
    [isActive]="rlaWebinars.isActive"
    (click)="sidebarComponentService.sendClickMenuEvent('Webinars')"
  >
    <a
      (click)="$event.preventDefault()"
      #rlaWebinars="routerLinkActive"
      [routerLink]="URL.WEBINARS | createUrl | async"
      routerLinkActive
    >
      <i class="sidebar-icon fal fa-video"></i>
      <span class="menu-title">Webinars</span>
    </a>
  </dougs-sidebar-menu-item>
  <dougs-sidebar-menu-item
    *ngIf="referralAccessService.canAccessReferral$ | async"
    [isCompact]="isCompact"
    [isActive]="rlaReferrer.isActive"
    [isAdmin]="
      !(referralAccessService.canCurrentCompanyAccess$ | async) && (referralAccessService.canCurrentUserAccess$ | async)
    "
    (click)="sidebarComponentService.sendClickMenuEvent('Sponsorship')"
  >
    <a
      (click)="$event.preventDefault()"
      #rlaReferrer="routerLinkActive"
      [routerLink]="URL.REFERRAL | createUrl | async"
      routerLinkActive
      data-cy="sidebar-menu-item-referral"
    >
      <i class="sidebar-icon fal fa-gift"></i>
      <span class="menu-title">Parrainage</span>
    </a>
  </dougs-sidebar-menu-item>
  <dougs-sidebar-menu-item
    *ngIf="sidebarModuleAccessComponentService.shouldShowHelpCenterLink$ | async"
    [isCompact]="isCompact"
    (click)="sidebarComponentService.sendClickMenuEvent('Help Center')"
  >
    <a href="https://aide.dougs.fr/fr/" target="_blank" rel="noopener">
      <i class="sidebar-icon fal fa-life-ring"></i>
      <span class="menu-title">Centre d'aide</span>
      <i
        *ngIf="!(userStateService.activeUser$ | async)?.metadata?.compactAppAside"
        class="fal fa-external-link mr-8"
      ></i>
    </a>
  </dougs-sidebar-menu-item>
  <dougs-sidebar-menu-item
    *ngIf="sidebarModuleAccessComponentService.shouldShowCreationLink$ | async"
    [isActive]="isRouterActiveAndNotificationClosed(rlaCreation)"
    [isCompact]="isCompact"
    [isAdmin]="true"
  >
    <a
      #rlaCreation="routerLinkActive"
      [class.disabled]="rlaCreation.isActive"
      [routerLink]="URL.CREATION | createUrl | async"
      routerLinkActive
    >
      <i class="sidebar-icon fal fa-building-circle-check"></i>
      <span class="menu-title">Création</span>
    </a>
  </dougs-sidebar-menu-item>
  <dougs-sidebar-menu-item
    *ngIf="sidebarModuleAccessComponentService.shouldShowAccountingReviewLink$ | async"
    [isActive]="isRouterActiveAndNotificationClosed(rlaAccountingReview)"
    [isCompact]="isCompact"
    [isAdmin]="true"
  >
    <a
      #rlaAccountingReview="routerLinkActive"
      [class.disabled]="rlaAccountingReview.isActive"
      [routerLink]="URL.ACCOUNTING_REVIEW | createUrl | async"
      routerLinkActive
    >
      <i class="sidebar-icon fal fa-calculator-simple"></i>
      <span class="menu-title">Révision</span>
    </a>
  </dougs-sidebar-menu-item>
  <dougs-sidebar-menu-item
    *ngIf="sidebarModuleAccessComponentService.shouldShowUsersListingLink$ | async"
    [isCompact]="isCompact"
    [isAdmin]="true"
  >
    <a (click)="sidebarComponentService.openUserModal()">
      <i class="sidebar-icon fal fa-user-magnifying-glass"></i>
      <span class="menu-title">Utilisateurs</span>
    </a>
  </dougs-sidebar-menu-item>
  <dougs-sidebar-menu-item
    *ngIf="sidebarModuleAccessComponentService.shouldShowTaskLink$ | async"
    [isActive]="isRouterActiveAndNotificationClosed(rlaTasks)"
    [isCompact]="isCompact"
    [isAdmin]="true"
  >
    <a
      #rlaTasks="routerLinkActive"
      [class.disabled]="rlaTasks.isActive"
      [routerLink]="URL.TASKS | createUrl | async"
      routerLinkActive
    >
      <i class="sidebar-icon fal fa-clipboard-check"></i>
      <span class="menu-title">Tâches</span>
    </a>
  </dougs-sidebar-menu-item>
  <dougs-sidebar-menu-item
    *ngIf="sidebarModuleAccessComponentService.shouldShowCockpit$ | async"
    [isActive]="isRouterActiveAndNotificationClosed(rlaCockpit)"
    [isCompact]="isCompact"
    [isAdmin]="true"
  >
    <a
      #rlaCockpit="routerLinkActive"
      [class.disabled]="rlaCockpit.isActive"
      [routerLink]="URL.COCKPIT | createUrl | async"
      routerLinkActive
    >
      <i class="sidebar-icon fal fa-rocket-launch"></i>
      <span class="menu-title">Cockpit</span>
      <dougs-badge
        type="primary"
        *ngIf="(cockpitStateService.totalUnseen$ | async) > 0"
        [ngClass]="{ 'ml-8': !isCompact }"
      >
        {{ cockpitStateService.totalUnseen$ | async }}
      </dougs-badge>
    </a>
  </dougs-sidebar-menu-item>
  <dougs-sidebar-menu-item
    *ngIf="sidebarModuleAccessComponentService.shouldShowSupportLink$ | async"
    [isCompact]="isCompact"
    [isAdmin]="true"
  >
    <a (click)="sidebarComponentService.openSupportModal()">
      <i class="sidebar-icon fal fa-headset"></i>
      <span class="menu-title">Support</span>
    </a>
  </dougs-sidebar-menu-item>
  <dougs-sidebar-menu-item
    *ngIf="sidebarComponentService.shouldShowCompanyLink$ | async"
    [isActive]="isRouterActiveAndNotificationClosed(rlaCompany)"
    [isCompact]="isCompact"
    [isAdmin]="true"
  >
    <a #rlaCompany="routerLinkActive" [routerLink]="URL.COMPANIES | createUrl | async" routerLinkActive>
      <i class="fal fa-folders"></i>
      <span class="menu-title">Dossiers</span>
    </a>
  </dougs-sidebar-menu-item>
  <dougs-user-menu
    [isCompact]="isCompact && !sidebarCompactComponentService.isCompactUnfolded$()"
    (click)="sidebarComponentService.sendClickMenuEvent('User Profile')"
  />
  <div
    *ngIf="sidebarModuleAccessComponentService.shouldShowChecklistLink$ | async"
    [ngClass]="{ 'mx-8 p-4': isCompact, 'mx-16 p-8': !isCompact }"
    (click)="toggleChecklist($event)"
    class="checklist-toggle-button pointer mt-16"
    dougs-sidebar-menu-bottom
  >
    <div class="checklist-toggle-button__img-container">
      <img src="images/onboarding/patrick.png" alt="Patrick Dougs" />
    </div>
    <p class="color-green-700">Bien démarrer<br />votre entreprise</p>
    <i class="fal fa-chevron-right color-primary-700"></i>
  </div>
</dougs-sidebar-menu>
<dougs-checklist
  [isOpen]="isChecklistOpened"
  [isCompact]="isCompact"
  (close)="closeChecklist()"
  (clickOutside)="closeChecklist()"
  dougsClickOutside
/>
<div *ngIf="sidebarComponentService.canSeeBanner$ | async" class="banner">
  <div class="notifications">
    <ng-container
      *ngIf="{ accountingOnboarding: accountingOnboardingStateService.accountingOnboarding$ | async } as onboarding"
    >
      <dougs-company-creation-xsell
        *ngIf="
          (companyServicesStateService.services$ | async)?.creation?.eligibility === 'eligible' &&
          !onboarding.accountingOnboarding?.data?.shouldShowCompanyCreationService &&
          (userStateService.activeUser$ | async)?.role === 'customer'
        "
      />
    </ng-container>

    <dougs-trial
      *ngIf="sidebarComponentService.showTrial$ | async"
      joyrideStep="trial"
      stepPosition="right"
      productTour
      [productTourContent]="(accountingProductsTourService.steps$ | async)?.['trial']"
    />
    <dougs-accounting-survey-reminder
      *ngIf="
        !(sidebarComponentService.showTrial$ | async) &&
        (sidebarComponentService.shouldShowAccountingSurveyReminder$ | async)
      "
    />

    @if (sidebarComponentService.shouldShowUpdateCard$ | async) {
      <dougs-update-payment-card />
    }
  </div>
</div>
<ng-content />
