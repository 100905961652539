import { computed, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { delay, filter, finalize, Observable, tap } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { UserMenuComponentService } from './user-menu.component.service';

@Injectable()
export class SidebarCompactComponentService {
  isHovered: WritableSignal<boolean> = signal(false);
  isHovered$: Signal<boolean> = this.isHovered.asReadonly();

  compactUnfoldedDisabled: WritableSignal<boolean> = signal(false);
  compactUnfoldedDisabled$: Signal<boolean> = this.compactUnfoldedDisabled.asReadonly();

  isCompactUnfolded$: Signal<boolean> = computed(
    () => (this.isHovered$() || this.userMenuComponentService.userMenuOpened$()) && !this.compactUnfoldedDisabled$(),
  );

  constructor(
    public readonly userMenuComponentService: UserMenuComponentService,
    private readonly router: Router,
  ) {}

  disableCompactUnfoldedOnNavigation$: Observable<void> = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    distinctUntilChanged((a, b) => this.isSameEventUrl(a, b)),
    map(() => this.compactUnfoldedDisabled.set(true)),
    delay(1000),
    tap(() => this.compactUnfoldedDisabled.set(false)),
    finalize(() => this.compactUnfoldedDisabled.set(false)),
  );

  onMouseEnter(): void {
    this.isHovered.set(true);
  }

  onMouseLeave(): void {
    this.isHovered.set(false);
  }

  private isSameEventUrl(eventA: NavigationEnd, eventB: NavigationEnd): boolean {
    return eventA.url === eventB.url;
  }
}
